<template>
  <div class="flex h-full flex-row items-center justify-end gap-0.5">
    <BaseButton
      v-for="action in otherActions"
      :key="action"
      size="xxs"
      type="ghost"
      class="capitalize"
      @click="params.context[action](params.data.id)"
    >
      <span :class="params.textColor ? params.textColor : params.colorByAction ? params.colorByAction[action] : ''">
        {{ action }}
      </span>
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('testItem')"
      title="Test Webhook"
      size="xxs"
      type="ghost"
      @click="params.context.testItem(params.data)"
    >
      <CustomIcons icon="webhook" class="h-4 w-4 text-red-400" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('pause')"
      size="xxs"
      type="ghost"
      @click="params.context.pauseItem(params.data.id)"
    >
      <PauseCircleIcon class="h-4 w-4 text-gray-100" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('resume')"
      size="xxs"
      type="ghost"
      @click="params.context.resumeItem(params.data.id)"
    >
      <PlayCircleIcon class="h-4 w-4 text-gray-100" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('view')"
      size="xxs"
      type="ghost"
      @click="params.context.viewItem(params.data.id)"
    >
      <EyeIcon class="h-4 w-4 text-gray-100" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('edit')"
      size="xxs"
      type="ghost"
      @click="params.context.editItem(params.data.id)"
    >
      <PencilSquareIcon class="h-4 w-4 text-blue-400" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('permissions')"
      title="Permissions"
      size="xxs"
      type="ghost"
      @click="params.context.openRoleModal(params.data)"
    >
      <UserGroupIcon class="h-4 w-4 text-red-400" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('delete')"
      size="xxs"
      type="ghost"
      @click="params.context.deleteItem(params.data.id)"
    >
      <XMarkIcon class="h-4 w-4 text-red-400" />
    </BaseButton>
    <BaseButton
      v-if="params.actions.includes('star')"
      size="xxs"
      type="ghost"
      @click.stop="params.context.toggleFavoriteItem(params.data.id, params.value === true)"
    >
      <StarIcon v-if="params.value && params.value === true" class="h-4 w-4 text-blue-500"></StarIcon>
      <StarIconOutline v-else class="h-4 w-4 text-white" />
    </BaseButton>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import {
  StarIcon,
  XMarkIcon,
  PlayCircleIcon,
  PauseCircleIcon,
  PencilSquareIcon,
  EyeIcon
} from '@heroicons/vue/20/solid';
import { UserGroupIcon } from '@heroicons/vue/24/outline';
import { StarIcon as StarIconOutline } from '@heroicons/vue/24/outline';
import CustomIcons from '@/components/CustomIcons.vue';

const props = defineProps({
  params: Object
});

const actions = computed(() => props.params.actions);
const otherActions = computed(() =>
  actions.value.filter(
    action => !['view', 'edit', 'delete', 'pause', 'resume', 'star', 'testItem', 'permissions'].includes(action)
  )
);
</script>
